/**
 *
 * Created by Filipe - 12/12/2022
 *
 */
import $ from 'jquery'
// notify options -> https://www.npmjs.com/package/pnotify#options
import {defaultModules, defaults, error as errorAlert, success as successAlert} from '@pnotify/core';
import '@pnotify/core/dist/PNotify.css';
import * as PNotifyBootstrap4 from '@pnotify/bootstrap4';
import * as PNotifyFontAwesome5Fix from '@pnotify/font-awesome5-fix';
import * as PNotifyFontAwesome5 from '@pnotify/font-awesome5';
import '@pnotify/bootstrap4/dist/PNotifyBootstrap4.css';
import {useStore} from "vuex";

import bootbox from 'bootbox'
import moment from "moment";

defaults.delay = 5000
defaults.width = '500px'

defaults.styling = "bootstrap4";

/*
const myStack = new Stack({
	dir1: 'up',
	firstpos1: 10,
	dir2: 'right',
	firstpos2: 15,
	modal: false,
	push: 'bottom',
	maxOpen: 5,
	context: document.getElementById('notify_wrapper'),
});

defaults.stack = myStack
*/

defaultModules.set(PNotifyBootstrap4, {});
defaultModules.set(PNotifyFontAwesome5Fix, {});
defaultModules.set(PNotifyFontAwesome5, {});


export function redirectBrowser(location) {
	window.location.href = location;
}


/**
 * handle de erros de axios
 *
 * @param error objecto devolvido pelo metodo catch do axios
 */
export function handleAjaxError(error, title) {
	if (error.response !== undefined) {
		if (error.response.data) {
			if (error.response.data.indexOf("<!DOCTYPE html>") > -1) {
				var errorEl = $(error.response.data).find("#error");
				let message = ''
				if (errorEl !== null && errorEl.length > 0)
					message = $(error.response.data).find("#error").html();
				else {
					message = $(error.response.data).filter("title").text();
				}
				errorAlert({
					title: title ? title : "Erro",
					text: message,
				});
			} else if (error.response.data) {
				errorAlert({
					title: title ? title : "Erro",
					text: error.response.data,
				});
			} else {
				errorAlert({
					title: title ? title : "Erro",
					text: error.message,
				});
			}
		} else {
			errorAlert({
				title: title ? title : "Erro",
				text: error.message,
			});
		}
		/*if (error.response.status === 500) {
			toastr.error(error.message, "Error");
		} else {
			toastr.error(error.response.data, "Error");
		}*/
	} else if (error.responseText !== undefined) {
		if (error.responseText.trim().length > 0) {
			errorAlert({
				title: title ? title : "Erro",
				text: error.responseText,
			});
		} else {
			errorAlert({
				title: title ? title : "Erro",
				text: error.statusText,
			});
		}
	} else if (error.message) {
		errorAlert({
			title: title ? title : "Erro",
			text: error.message,
		});
	} else {
		errorAlert({
			title: title ? title : "Erro",
			text: error,
		});
	}
}

export function showSuccess(title, message) {
	successAlert({
		title: title,
		text: message,
	});
}

export function showError(title, message1, message2) {
	if (typeof message2 !== 'undefined' && message2.indexOf("<!DOCTYPE html>") > -1) {
		// faz o handle da pagina de erro devolvida pelo Global.onError ou Global.onBadRequest
		const errorEl = $(message2).find("#error");
		if (errorEl !== null && errorEl.length > 0)
			message2 = $(message2).find("#error").html();
		else {
			message2 = $(message2).filter("title").text();
		}
		// console.log(message1 + message2);
	}
	if (message2 === undefined)
		message2 = "";

	errorAlert({
		title: title,
		text: message1 + message2,
	});
}

export function showCustomDialog(title, message, okLabel, okClass, okCallback) {
	// eslint-disable-next-line
	bootbox.dialog({
		message: message,
		title: title,
		buttons: {
			cancel: {
				label: "Cancelar",
				className: "btn-default"
			},
			ok: {
				label: okLabel,
				className: okClass,
				callback: okCallback
			}
		}
	});
}

export function showSuccessDialog(title, message) {
	bootbox.dialog({
		message: '<div class="alert alert-success" role="alert">' + message + '</div>',
		title: title,
		buttons: {
			ok: {
				label: "OK",
				className: "btn-primary",
			}
		}
	});
}


export function showErrorDialog(title, message) {
	bootbox.dialog({
		message: '<div class="alert alert-danger" role="alert">' + message + '</div>',
		title: title,
		buttons: {
			ok: {
				label: "Fechar",
				className: "btn-primary",
			}
		}
	});
}

export function showWarningDialog(title, message) {
	bootbox.dialog({
		message: '<div class="alert alert-warning" role="alert">' + message + '</div>',
		title: title,
		buttons: {
			ok: {
				label: "Fechar",
				className: "btn-primary",
			}
		}
	});
}

/**
 // query string: ?foo=lorem&bar=&baz
 var foo = getParameterByName('foo'); // "lorem"
 var bar = getParameterByName('bar'); // "" (present with empty value)
 var baz = getParameterByName('baz'); // "" (present with no value)
 var qux = getParameterByName('qux'); // null (absent)

 */
export function getParameterByName(name, url) {
	if (!url) {
		url = window.location.href;
	}
	name = name.replace(/[\[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const datatableLanguage = {
	"lengthMenu": "Mostrar _MENU_ entradas por página",
	"zeroRecords": "Não existem dados para mostrar",
	"info": "Página _PAGE_ de _PAGES_ para _TOTAL_ entradas",
	"infoEmpty": "0 entradas",
	"infoFiltered": "(filtrado de _MAX_ entradas)",
	"loadingRecords": "A carregar dados...",
	"processing": "A Processar...",

	"search": "Filtrar:",
	"paginate": {
		"first": "Primeira",
		"last": "Última",
		"next": "Seguinte",
		"previous": "Anterior"
	}
};

export function removeFromArray(array, element) {
	var index = array.indexOf(element);

	if (index !== -1) {
		array.splice(index, 1);
	}
}


export function hasPermission(permission) {
	const store = useStore()
	const user = store.getters.getLoggedUser
	// console.debug("cenas ", user, permission)
	if (!user || !user.permissions) {
		return false
	}
	return user.permissions.includes(permission)
}

export function hasAnyPermission(permissionAny) {
	const store = useStore()
	const user = store.getters.getLoggedUser
	// console.debug("cenas ", user, permission)
	if (!user || !user.permissions) {
		return false
	}
	const value = permissionAny.replace('ANY', '')
	for (const permission of user.permissions) {
		// console.debug('permission: ', permission)
		if (permission.startsWith(value)) {
			// console.debug('found any!')
			return true
		}
	}
	return false
}

export function setNavbarMarca(marca) {
	const selectedMarcaContainer = document.getElementById('selected_marca_container');
	const selectedMarcaNameElem = document.getElementById('selected_marca_name');
	const selectedMarcaImgElem = document.getElementById('selected_marca_img');
	selectedMarcaNameElem.textContent = marca.nome;
	selectedMarcaImgElem.src = `/marketing/assets/images/${marca.logoName}`;
	selectedMarcaContainer.style.display = 'block';
}

export const getMarketingYearsList = () => {
	let yearIter = 2023;
	const year = moment().year();
	const list = [];
	do {
		list.push({id: yearIter, text: yearIter});
	} while (yearIter++ < year);
	return list.reverse();
}


export function formatNumberWithComma(x) {
	if (!x) {
		return "";
	}
	x = x.toString().replace(",", ".");
	if (!isNaN(x)) {
		x = parseFloat(x).toFixed(2);
	}
	if (isNaN(x)) {
		return "";
	}
	return x.toString().replace("\.", ",");
}

export function parseNumberWithCommas(x) {
	return parseFloat(x.toString().replace(/\,/g, "."));
}

export function getEstadosList(includeTodos) {
	const list = [
		{id: 'POR_APROVAR', text: 'Por Aprovar'},
		{id: 'APROVADA', text: 'Aprovada'},
		{id: 'NAO_APROVADA', text: 'Incompleto'},
		{id: 'POR_VALIDAR', text: 'Por Validar'},
		{id: 'NAO_VALIDADA', text: 'Não Validada'},
		{id: 'VALIDADA', text: 'Validada'},
		{id: 'NAO_ELEGIVEL', text: 'Não elegível'},
		{id: 'NAO_REALIZADA', text: 'Não realizada'},
	];
	if (includeTodos) {
		list.unshift({id: null, text: '- TODOS -'});
	}
	return list;
}


export function estadoBadge(estado) {
	switch (estado) {
		case 'RASCUNHO':
		case 'POR_APROVAR':
		case 'POR_VALIDAR':
			return 'badge-warning';
		case 'APROVADA':
			return 'badge-primary';
		case 'NAO_APROVADA':
		case 'NAO_APROVADO':
			return 'badge-danger';
		case 'VALIDADA':
		case 'APROVADO':
			return 'badge-success';
		case 'NAO_ELEGIVEL':
			return 'badge-light';
		case 'NAO_REALIZADA':
			return 'badge-light';
	}
}

export function canalLabel(canal) {
	const canais = [
		{id: "CAMPANHAS_REGIONAIS", text: 'Campanhas Regionais'},
		{id: "EXPOSICOES", text: 'Exposições'},
		{id: "PATROCINIO", text: 'Patrocínios, Eventos e Ativação de Marca'},
		{id: "DIGITAL", text: 'Digital'},
		{id: "CAMPANHAS_PERFORMANCE_DIGITAL", text: 'Campanhas de Performance Digital'},
		{id: "CRM", text: 'CRM'},
		{id: "OUTROS", text: 'Outros'},
	];
	const found = canais.find(value => value.id === canal);
	// console.debug('canal found ', found);
	if (!found) {
		return '';
	}
	return found.text;
}


export const tiposFrota = [
	{
		id: 'PARQUE_IMPRENSA',
		text: 'Parque Imprensa',
	},
	{
		id: 'FROTAS',
		text: 'Frotas',
	},
	{
		id: 'VIATURA_SERVICO',
		text: 'Viatura de Serviço',
	},
	{
		id: 'PARCERIAS',
		text: 'Parcerias',
	},
	{
		id: 'LEASECAR',
		text: 'Leasecar',
	},
	{
		id: 'OP_CANTER',
		text: 'OP. Canter',
	},
	{
		id: 'PARA_VENDA',
		text: 'Parado para Venda',
	},
];

export function canEditCedencia(dataLevantamento) {
	// console.debug(dataLevantamento)
	const date = moment(dataLevantamento, 'DD-MM-YYYY');
	return moment().isSameOrBefore(date, 'day');
}


export function startEndDateValidator(value, siblings) {
	if (!value) {
		return true;
	}
	if (!siblings.dataLevantamento) {
		return true;
	}
	const startDate = moment(siblings.dataLevantamento, 'DD-MM-YYYY');
	const endDate = moment(value, 'DD-MM-YYYY');
	return startDate.isBefore(endDate);
}


export const TRIMESTRES_FATURACAO = [
	{
		id: 'TRIMESTRE_1',
		text: '1º Trimestre'
	},
	{
		id: 'TRIMESTRE_2',
		text: '2º Trimestre'
	},
	{
		id: 'TRIMESTRE_3',
		text: '3º Trimestre'
	},
	{
		id: 'TRIMESTRE_4',
		text: '4º Trimestre'
	},

];

export const PRODUCTS_LIST = (marca) => {
	switch (marca) {
		case 1: // Kia
			return [
				{
					id: 'Picanto',
					text: 'Picanto'
				},
				{
					id: 'Stonic',
					text: 'Stonic'
				},
				{
					id: 'Ceed',
					text: 'Ceed'
				},
				{
					id: 'Ceed SW',
					text: 'Ceed SW'
				},
				{
					id: 'XCeed',
					text: 'XCeed'
				},
				{
					id: 'XCeed PHEV',
					text: 'XCeed PHEV'
				},
				{
					id: 'ProCeed',
					text: 'ProCeed'
				},
				{
					id: 'Niro HEV',
					text: 'Niro HEV'
				},
				{
					id: 'Niro EV',
					text: 'Niro EV'
				},
				{
					id: 'Sportage',
					text: 'Sportage'
				},
				{
					id: 'Sportage HEV',
					text: 'Sportage HEV'
				},
				{
					id: 'Sportage PHEV',
					text: 'Sportage PHEV'
				},
				{
					id: 'Sorento',
					text: 'Sorento'
				},
				{
					id: 'Sorento HEV',
					text: 'Sorento HEV'
				},
				{
					id: 'Sorento PHEV',
					text: 'Sorento PHEV'
				},
				{
					id: 'EV3',
					text: 'EV3'
				},
				{
					id: 'EV6',
					text: 'EV6'
				},
				{
					id: 'EV9',
					text: 'EV9'
				}
			];
		case 3: // Mitsubishi Motors
			return [
				{
					id: 'Space Star',
					text: 'Space Star'
				},
				{
					id: 'Colt',
					text: 'Colt'
				},
				{
					id: 'ASX',
					text: 'ASX'
				},
				{
					id: 'ASX PHEV',
					text: 'ASX PHEV'
				},
				{
					id: 'Eclipse Cross PHEV',
					text: 'Eclipse Cross PHEV'
				},
				{
					id: 'Outlander PHVE',
					text: 'Outlander PHVE'
				}
			];
		case 4: // Fuso
			return [
				{
					id: 'Canter',
					text: 'Canter'
				},
				{
					id: 'eCanter',
					text: 'eCanter'
				}
			];
		case 8: // KGM
			return [
				{
					id: "Musso",
					text: "Musso"
				},
				{
					id: "Torres",
					text: "Torres"
				},
				{
					id: "Torres EVX",
					text: "Torres EVX"
				},
				{
					id: "Tivoli",
					text: "Tivoli",
				},
				{
					id: "Rexton",
					text: "Rexton",
				},
				{
					id: "Korando",
					text: "Korando",
				},
			]

	}
	return [];


}

