import {createStore} from 'vuex'
import axios from "axios";
import {setNavbarMarca} from "@/utils";
import marketingregional from "@/store/modules/marketingregional";


export const store = createStore({
	state() {
		return {
			loggedUser: null,
		}
	},

	getters: {
		getLoggedUser: (state) => state.loggedUser,
	},

	mutations: {
		setLoggedUser(state, user) {
			state.loggedUser = user
		}
	},

	actions: {
		loadLoggedUser({commit}) {
			// eslint-disable-next-line
			const url = jsRoutes.controllers.Application.loadWhoAmI().url;
			axios.get(url)
				.then((response) => {
					commit('setLoggedUser', response.data)
					if (store.getters['concessionario/getSelectedMarcaId'] && store.getters['concessionario/getSelectedMarca'] === null) {
						store.getters.getLoggedUser.availableMarcas.forEach(marca => {
							//console.debug('marca ', marca)
							if (marca.id+"" === store.getters['concessionario/getSelectedMarcaId']+"") {
								// console.debug('STORE going to set marca ', marca)
								store.commit('concessionario/setSelectedMarca', marca)
								setNavbarMarca(marca)
							}
						})
					}
				})
				.catch(() => {
					commit('setLoggedUser', null)
				})

		}
	},

	modules: {
		marketingregional,

		ficheiros: {
			namespaced: true,
			state() {
				return {
					selectedMarca: null,
					pastaAtiva: null,
				}
			},

			getters: {
				getSelectedMarca: state => state.selectedMarca,
				getPastaAtiva: state => state.pastaAtiva
			},

			mutations: {
				setSelectedMarca(state, marca) {
					state.selectedMarca = marca
				},
				setPastaAtiva(state, pasta) {
					state.pastaAtiva = pasta
				},

			}
		},

		concessionario: {
			namespaced: true,
			state() {
				return {
					selectedMarca: null,
					selectedMarcaId: null,
					listaPastas: [],
				}
			},
			getters: {
				getSelectedMarca: state => state.selectedMarca,
				getSelectedMarcaId: state => state.selectedMarcaId,
				getListaPastas: state => state.listaPastas,
			},
			mutations: {
				setSelectedMarca(state, marca) {
					state.selectedMarca = marca
				},
				setSelectedMarcaId(state, marcaId) {
					state.selectedMarcaId = marcaId
				},
				setListaPastas(state, pastas) {
					state.listaPastas = pastas
				},
			},
			actions: {
				loadPastasTopo({commit}, marcaId) {
					// eslint-disable-next-line
					const url = jsRoutes.controllers.ConcessionarioController.loadListaPastasTopoMarca(marcaId).url;
					return axios.get(url)
						.then(response => {
							// console.debug('going to set pastas')
							commit('setSelectedMarcaId', marcaId)
							commit('setListaPastas', response.data)
						})

				}
			}
		}

	}
})
