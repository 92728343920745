import {createRouter, createWebHistory} from 'vue-router'


const routes = [
	// IMPORTADOR
	{
		path: '/marketing/i/ficheiros',
		component: () => import(/* webpackChunkName: "importadorficheiros" */ '@/views/importador/ficheiros/FicheirosModule.vue'),
		children: [
			{
				path: '',
				name: 'EscolhaMarca',
				component: () => import(/* webpackChunkName: "importadorficheiros" */ '@/views/EscolhaMarca.vue'),
			},
			{
				path: 'pastas',
				name: 'FicheirosMain',
				component: () => import(/* webpackChunkName: "importadorficheiros" */ '@/views/importador/ficheiros/FicheirosMain.vue'),
			},
			{
				path: ':id/editar',
				name: 'EditarFicheiro',
				component: () => import(/* webpackChunkName: "importadorficheiros" */ '@/views/importador/ficheiros/EditarFicheiro.vue'),
			},
			{
				path: ':id/carregar',
				name: 'CarregarFicheiro',
				component: () => import(/* webpackChunkName: "importadorficheiros" */ '@/views/importador/ficheiros/CarregarFicheiro.vue'),
			},
			{
				path: ':id/carregar-grande',
				name: 'CarregarFicheiroGrande',
				component: () => import(/* webpackChunkName: "importadorficheiros" */ '@/views/importador/ficheiros/CarregarFicheiroGrande.vue'),
			},
			{
				path: ':id/completar-grande',
				name: 'CompletarFicheiroGrande',
				component: () => import(/* webpackChunkName: "importadorficheiros" */ '@/views/importador/ficheiros/CompletarFicheiroGrande.vue'),
			},
		]
	},
	{
		path: '/marketing/i/imagens-login',
		name: 'ImagensLogin',
		component: () => import(/* webpackChunkName: "importadorimagenslogin" */ '@/views/importador/ImagensLogin.vue'),
	},
	{
		path: '/marketing/i/estatisticas',
		name: 'EstatisticasMain',
		component: () => import(/* webpackChunkName: "importadorimagenslogin" */ '@/views/importador/EstatisticasMain.vue'),
	},
	{
		path: '/marketing/i/concessionarios',
		name: 'ConcessionariosModule',
		component: () => import(/* webpackChunkName: "importadorconcessionarios" */ '@/views/importador/concessionarios/ConcessionariosModule.vue'),
		children: [
			{
				path: '',
				name: 'ConcessionariosList',
				component: () => import(/* webpackChunkName: "importadorconcessionarios" */ '@/views/importador/concessionarios/ConcessionariosList.vue'),
			},
			{
				path: 'criar',
				name: 'ConcessionarioCreate',
				component: () => import(/* webpackChunkName: "importadorconcessionarios" */ '@/views/importador/concessionarios/ConcessionarioCreate.vue'),
			},
			{
				path: ':id/editar',
				name: 'ConcessionarioEdit',
				component: () => import(/* webpackChunkName: "importadorconcessionarios" */ '@/views/importador/concessionarios/ConcessionarioEdit.vue'),
			},
		]
	},
	{
		path: '/marketing/i/utilizadores',
		component: () => import(/* webpackChunkName: "importadorutilizadores" */ '@/views/importador/utilizadores/UtilizadoresModule.vue'),
		children: [
			{
				path: '',
				name: 'UtilizadoresMain',
				component: () => import(/* webpackChunkName: "importadorutilizadores" */ '@/views/importador/utilizadores/UtilizadoresMain.vue'),
			},
			{
				path: 'concessionario/adicionar',
				name: 'CreateUserConcessionario',
				component: () => import(/* webpackChunkName: "importadorutilizadores" */ '@/views/importador/utilizadores/CreateUserConcessionario.vue'),
			},
			{
				path: 'concessionario/:id/editar',
				name: 'EditUserConcessionario',
				component: () => import(/* webpackChunkName: "importadorutilizadores" */ '@/views/importador/utilizadores/EditUserConcessionario.vue'),
			},
			{
				path: 'importador/adicionar',
				name: 'CreateUserImportador',
				component: () => import(/* webpackChunkName: "importadorutilizadores" */ '@/views/importador/utilizadores/CreateUserImportador.vue'),
			},
			{
				path: 'importador/:id/editar',
				name: 'EditUserImportador',
				component: () => import(/* webpackChunkName: "importadorutilizadores" */ '@/views/importador/utilizadores/EditUserImportador.vue'),
			},
		]
	},
	{
		path: '/marketing/i/marketing-regional',
		meta: {
			module: 'marketingregionalimp'
		},
		component: () => import(/* webpackChunkName: "marketingregionalimp" */ '@/views/importador/marketingregional/ImpMarketingRegionalModule.vue'),
		children: [
			{
				path: 'importar-accoes',
				name: 'ImportarAccoes',
				component: () => import(/* webpackChunkName: "importarAccoes" */ '@/views/admin/ImportarAccoes.vue'),
			},
			{
				path: 'planos',
				name: 'PlanosAnuaisList',
				meta: {
					module: 'marketingregionalimp',
					tab: 'planos'
				},
				component: () => import(/* webpackChunkName: "marketingregionalimp" */ '@/views/importador/marketingregional/PlanosAnuaisList.vue'),
			},
			{
				path: 'plano/:id',
				name: 'PlanoAnualDetail',
				props: true,
				meta: {
					module: 'marketingregionalimp',
					tab: 'planos'
				},
				component: () => import(/* webpackChunkName: "marketingregionalimp" */ '@/views/importador/marketingregional/PlanoAnualDetail.vue'),
			},
			{
				path: 'kpis',
				name: 'ImpMktRegionalKpis',
				meta: {
					module: 'marketingregionalimp'
				},
				component: () => import(/* webpackChunkName: "marketingregionalimp" */ '@/views/importador/marketingregional/ImpMktRegionalKpis.vue'),
			},
			{
				path: 'acoes',
				name: 'ImpAcoesMarketingTabPanel',
				meta: {
					module: 'marketingregionalimp'
				},
				component: () => import(/* webpackChunkName: "marketingregionalimp" */ '@/views/importador/marketingregional/ImpAcoesMarketingTabPanel.vue'),
			},
			{
				path: 'deadlines',
				name: 'ImpMktRegionalDeadlines',
				meta: {
					module: 'marketingregionalimp'
				},
				component: () => import(/* webpackChunkName: "marketingregionalimp" */ '@/views/importador/marketingregional/ImpMktRegionalDeadlines.vue'),
			},

		]
	},
	{
		path: '/marketing/i/gestao-frota',
		meta: {
			module: 'gestaofrotaimp'
		},
		component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/GestaoFrotaModule.vue'),
		children: [
			{
				path: 'importar-clientes',
				meta: {
					module: 'gestaofrotaimp'
				},
				component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/ImportarClientesPanel.vue'),

			},
			{
				path: 'importar-viaturas',
				meta: {
					module: 'gestaofrotaimp'
				},
				component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/ImportarViaturasPanel.vue'),

			},
			{
				path: 'cedencias',
				meta: {
					module: 'gestaofrotaimp',
					submodule: 'cedencias'
				},
				component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/cedencias/CedenciasSubmodule.vue'),
				children: [
					{
						path: 'lista',
						name: 'CedenciasListPanel',
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'cedencias'
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/cedencias/CedenciasListPanel.vue'),
					},
					{
						path: 'condutores',
						name: 'CedenciasCondutoresPanel',
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'cedencias'
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/cedencias/CedenciasCondutoresPanel.vue'),
					},
					{
						path: 'clientes',
						name: 'CedenciasClientesPanel',
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'cedencias'
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/cedencias/CedenciasClientesPanel.vue'),
					},
					{
						path: 'criar-cedencia',
						name: 'CedenciaCreate',
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'cedencias'
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/cedencias/CedenciaCreatePanel.vue'),
					},
					{
						path: ':id/detalhe-cedencia',
						name: 'CedenciaDetalhe',
						props: true,
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'cedencias'
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/cedencias/CedenciaDataPanel.vue'),
					},
					{
						path: 'historico',
						name: 'CedenciasHistoricoPanel',
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'cedencias'
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/cedencias/CedenciasHistoricoPanel.vue'),
					},

				]
			},
			{
				path: 'frota',
				meta: {
					module: 'gestaofrotaimp',
					submodule: 'frota',
				},
				component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/frota/FrotaSubmodule.vue'),
				children: [
					{
						path: 'viaturas',
						name: 'ViaturasFrota',
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'frota',
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/frota/FrotaViaturasPanel.vue'),
					},
					{
						path: 'utilizadores',
						name: 'FrotaUtilizadoresPanel',
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'frota',
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/frota/FrotaUtilizadoresPanel.vue'),
					},
					{
						path: 'criar-viatura',
						name: 'CriarViaturaFrota',
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'frota',
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/frota/CriarViaturaFrotaPanel.vue'),
					},
					{
						path: ':id/detalhe-viatura',
						name: 'DetalheViaturaFrota',
						props: true,
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'frota',
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/frota/EditarViaturaFrotaPanel.vue'),
					},
					{
						path: ':id/editar-viatura',
						name: 'EditarViaturaFrota',
						props: true,
						meta: {
							module: 'gestaofrotaimp',
							submodule: 'frota',
						},
						component: () => import(/* webpackChunkName: "gestaofrotaimp" */ '@/views/importador/gestaofrota/frota/EditarViaturaFrotaPanel.vue'),
					},


				]
			},
		]
	},

	{
		path: '/marketing/i/cedencias',
		meta: {
			module: 'cedencias'
		},
		component: () => import(/* webpackChunkName: "cedencias" */ '@/views/cedencias/CedenciasModule.vue'),
		children: [
			{
				path: '',
				name: 'CedenciasOptions',
				meta: {
					module: 'cedencias'
				},
				component: () => import(/* webpackChunkName: "cedencias" */ '@/views/cedencias/CedenciasOptionsPanel.vue'),
			},
			{
				path: 'levantamento',
				name: 'CedenciasLevantamento',
				meta: {
					module: 'cedencias'
				},
				component: () => import(/* webpackChunkName: "cedencias" */ '@/views/cedencias/CedenciasLevantamentoPanel.vue'),
			},
			{
				path: 'entrega',
				name: 'CedenciasEntrega',
				meta: {
					module: 'cedencias'
				},
				component: () => import(/* webpackChunkName: "cedencias" */ '@/views/cedencias/CedenciasEntregaPanel.vue'),
			},


		]
	},


	// CONCESSIONÁRIO
	{
		path: '/marketing/c',
		component: () => import(/* webpackChunkName: "concessionario" */ '@/views/concessionario/ConcessionarioModule.vue'),
		children: [
			{
				path: '',
				name: 'EscolhaMarcaConcessionario',
				component: () => import(/* webpackChunkName: "concessionario" */ '@/views/EscolhaMarca.vue'),
			},
			{
				path: 'marca/:marcaId/home',
				name: 'ConcessionarioHome',
				component: () => import(/* webpackChunkName: "concessionario" */ '@/views/concessionario/ConcessionarioHome.vue'),
			},
			{
				path: 'marca/:marcaId/cesto',
				name: 'CestoMainPanel',
				component: () => import(/* webpackChunkName: "concessionario" */ '@/views/concessionario/CestoMainPanel.vue'),
			},
			{
				path: 'marca/:marcaId/pasta/:id',
				name: 'PastaConcessionario',
				component: () => import(/* webpackChunkName: "concessionario" */ '@/views/concessionario/PastaConcessionario.vue'),
			},
		],
	},
	{
		path: '/marketing/c/marketing-regional',
		meta: {
			module: 'marketingregional'
		},
		component: () => import(/* webpackChunkName: "marketingregional" */ '@/views/concessionario/marketingregional/MarketingRegionalModule.vue'),
		children: [
			{
				path: 'plano-anual',
				name: 'PlanoAnualTabPanel',
				meta: {
					module: 'marketingregional'
				},
				component: () => import(/* webpackChunkName: "marketingregional" */ '@/views/concessionario/marketingregional/PlanoAnualTabPanel.vue'),
			},
			{
				path: 'acoes-marketing',
				name: 'AcoesMarketingTabPanel',
				meta: {
					module: 'marketingregional'
				},
				component: () => import(/* webpackChunkName: "marketingregional" */ '@/views/concessionario/marketingregional/AcoesMarketingTabPanel.vue'),
			},
			{
				path: 'deadlines',
				name: 'ConcDeadlinesTabPanel',
				meta: {
					module: 'marketingregional'
				},
				component: () => import(/* webpackChunkName: "marketingregional" */ '@/views/concessionario/marketingregional/DeadlinesTabPanel.vue'),
			},
			{
				path: 'faturas',
				name: 'ConcFaturasTabPanel',
				meta: {
					module: 'marketingregional'
				},
				component: () => import(/* webpackChunkName: "marketingregional" */ '@/views/concessionario/marketingregional/FaturasTabPanel.vue'),
			},

		]
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router
