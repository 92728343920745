/**
 *
 * Created by Filipe - 23/02/2023
 *
 */
import axios from "axios";
import {showError} from "@/utils";


const state = {
	filter: {
		marcaId: null,
		ano: null,
	},
	acoesConcId: null,

	planosList: [],

	anosList: [],
	marcasUser: [],

	concessionariosMarca: [],
}


const getters = {
	getAnosList: (state) => state.anosList,
	getMarcasList: (state) => state.marcasUser,
	getPlanosList: (state) => state.planosList,
	getFilter: state => state.filter,
	getAcoesConcId: state => state.acoesConcId,
	getConcessionariosMarca: state => state.concessionariosMarca,
}


const mutations = {
	setFilter(state, obj) {
		state.filter = obj;
	},
	setAcoesConcId(state, obj) {
		state.acoesConcId = obj;
	},
	setPlanosList(state, list) {
		state.planosList = list;
	},
	setMarcasList(state, list) {
		state.marcasUser = list;
	},

	setAnosList(state, list) {
		state.anosList = list;
		if (state.filter.ano === null) {
			state.filter.ano = list[0].id;
		}
	},
	setConcessionariosMarca(state, list) {
		state.concessionariosMarca = list;
	},

}


const actions = {

	loadMarcasLoggedImp({state, commit}) {
		// eslint-disable-next-line
		const url = jsRoutes.controllers.MarketingRegionalImpController.loadMarcasUserSelectList().url;
		axios.get(url)
			.then(response => {
				// console.debug(response);
				commit('setMarcasList', response.data);
				if (!state.filter.marcaId) {
					commit('setFilter', {
						marcaId: response.data[0].id,
						ano: state.anosList[0].id
					});
				}
			})
			.catch(() => {
				showError('Erro', 'Ocorreu um erro no carregamento das marcas');
			})
	},

	loadPlanosImp({state, commit}) {
		// eslint-disable-next-line
		const url = jsRoutes.controllers.MarketingRegionalImpController.loadPlanosList(state.filter.marcaId, state.filter.ano).url;
		axios.get(url)
			.then(response => {
				// console.debug(response);
				commit('setPlanosList', response.data);
			})
			.catch(() => {
				// console.error(error);
				showError('Erro', 'Ocorreu um erro no carregamento dos Planos Anuais');
			})
	},

	loadConcessionariosByMarca({state, commit}) {
		// eslint-disable-next-line
		const url = jsRoutes.controllers.MarketingRegionalImpController.loadMarcaConcessionariosSelectList(state.filter.marcaId).url;
		axios.get(url)
			.then(response => {
				// console.debug(response);
				commit('setConcessionariosMarca', response.data);
			})
			.catch(() => {
				// console.error(error);
				showError('Erro', 'Ocorreu um erro no carregamento dos Concessionários');
			})

	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}




